.wrapper header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 108px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); }
  .wrapper header .user {
    margin-left: auto;
    padding: 0 64px;
    cursor: pointer; }
    @media only screen and (max-width: 768px) {
      .wrapper header .user {
        padding: 0 32px; } }
    .wrapper header .user > i {
      color: #922c88;
      font-size: 32px; }
    .wrapper header .user span {
      color: #303030;
      font-size: 14px;
      margin-right: 8px; }
    .wrapper header .user img {
      width: 48px;
      height: 48px;
      border-radius: 50%; }
      @media only screen and (max-width: 768px) {
        .wrapper header .user img {
          width: 32px;
          height: 32px; } }
    .wrapper header .user::after {
      content: ' ';
      display: inline-block;
      vertical-align: .255em;
      margin-left: .255em;
      width: 3px;
      height: 3px;
      border-top: .3em solid;
      border-right: .3em solid transparent;
      border-bottom: 0;
      border-left: .3em solid transparent; }
    .wrapper header .user .menu {
      position: absolute;
      top: 0;
      right: 0;
      will-change: transform;
      transform: translate3d(-60px, 90px, 0px);
      width: 160px;
      border: 1px solid #d7d7d7;
      background-color: #fff;
      margin: 0;
      list-style: none;
      padding: 8px 0px; }
      .wrapper header .user .menu > li {
        padding: .75rem 1.5rem;
        color: #303030;
        font-size: 12px;
        cursor: pointer;
        display: flex;
        align-items: center; }
        .wrapper header .user .menu > li i {
          font-size: 24px;
          margin-right: 8px; }
        .wrapper header .user .menu > li:hover {
          background-color: #f3f3f3; }
  .wrapper header .hamburger {
    display: flex;
    justify-content: center;
    color: #922c88;
    font-size: 32px;
    width: 128px;
    cursor: pointer; }
  .wrapper header .logo {
    width: 200px;
    position: absolute;
    left: 50%;
    margin-left: -100px; }
    .wrapper header .logo svg {
      width: 200px; }
  .wrapper header .visit-site {
    outline: none;
    padding: 8px;
    color: #8f8f8f;
    margin-left: 32px; }
    @media only screen and (max-width: 768px) {
      .wrapper header .visit-site {
        display: none; } }
    .wrapper header .visit-site img {
      width: 24px;
      height: 24px; }
    .wrapper header .visit-site a {
      color: #8f8f8f;
      text-decoration: none; }
  .wrapper header .language .menu {
    position: absolute;
    top: 0;
    left: 0;
    will-change: transform;
    transform: translate3d(65px, 90px, 0px);
    width: 160px;
    border: 1px solid #d7d7d7;
    background-color: #fff;
    margin: 0;
    list-style: none;
    padding: 8px 0px; }
    .wrapper header .language .menu > li {
      padding: .75rem 1.5rem;
      color: #303030;
      font-size: 12px;
      cursor: pointer; }
      .wrapper header .language .menu > li img {
        width: 24px;
        margin-right: 8px; }
      .wrapper header .language .menu > li:hover {
        background-color: #f3f3f3; }
  .wrapper header .language button {
    background-color: #f8f8f8 !important;
    outline: none;
    color: #8f8f8f; }
    .wrapper header .language button img {
      width: 24px;
      margin-right: 8px; }
    .wrapper header .language button:active {
      background-color: #dae0e5 !important;
      outline: none; }
    .wrapper header .language button:focus {
      background-color: #dae0e5 !important;
      outline: none; }
    .wrapper header .language button::after {
      content: ' ';
      display: inline-block;
      vertical-align: .255em;
      margin-left: .255em;
      width: 3px;
      height: 3px;
      border-top: .3em solid;
      border-right: .3em solid transparent;
      border-bottom: 0;
      border-left: .3em solid transparent; }
  @media only screen and (max-width: 768px) {
    .wrapper header {
      background-color: #fff;
      height: 80px; }
      .wrapper header .logo {
        width: 64px;
        position: absolute;
        left: 50%;
        margin-left: -32px;
        height: 32px; }
        .wrapper header .logo svg {
          width: 64px;
          height: 32px; }
      .wrapper header .hamburger {
        display: flex;
        justify-content: center;
        color: #922c88;
        font-size: 26px;
        width: 64px; }
      .wrapper header .user span {
        display: none; } }

.wrapper .animate-sidebar {
  animation-name: sidebar-animation;
  animation-duration: 0.5s; }

.wrapper .animate-submenu {
  animation-name: subsidebar-animation;
  animation-duration: 0.5s; }

.wrapper .sidebar {
  width: 128px;
  float: left;
  height: 100%;
  min-height: calc(100vh - 128px);
  background-color: #fff;
  position: fixed;
  top: 0;
  margin-top: 108px;
  left: 0;
  padding-bottom: 108px;
  overflow-y: auto;
  z-index: 3; }
  @media only screen and (max-width: 768px) {
    .wrapper .sidebar {
      top: 0px;
      margin-top: 64px; } }
  .wrapper .sidebar > ul {
    list-style: none;
    margin: 0;
    text-align: center;
    z-index: 99;
    padding: 0; }
    .wrapper .sidebar > ul > li {
      padding: 16px;
      border: 1px solid #f3f3f3;
      background-color: #fff;
      border-left: 6px solid #fff;
      cursor: pointer;
      width: 100%;
      float: left; }
      .wrapper .sidebar > ul > li img {
        width: 100%;
        padding: 8px 24px; }
      .wrapper .sidebar > ul > li:hover {
        border-left: 6px solid #922c88; }
        .wrapper .sidebar > ul > li:hover > a > span {
          color: #922c88; }
        .wrapper .sidebar > ul > li:hover > a > i {
          color: #922c88; }
      .wrapper .sidebar > ul > li > a {
        width: 100%;
        color: #fff; }
        .wrapper .sidebar > ul > li > a > span {
          color: #303030; }
        .wrapper .sidebar > ul > li > a i {
          width: 100%;
          float: left;
          font-size: 26px;
          color: #303030; }
      .wrapper .sidebar > ul > li:hover {
        background-color: #f3f3f3; }
      .wrapper .sidebar > ul > li .sub-menu {
        position: fixed;
        top: 108px;
        left: 128px;
        height: calc(100vh - 108px);
        width: 230px;
        background-color: #fff;
        margin: 0;
        list-style: none;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
        z-index: 5;
        padding: 0; }
        @media only screen and (max-width: 768px) {
          .wrapper .sidebar > ul > li .sub-menu {
            top: 80px;
            height: calc(100vh - 80px); } }
        .wrapper .sidebar > ul > li .sub-menu > li {
          width: 100%;
          float: left; }
          .wrapper .sidebar > ul > li .sub-menu > li > a {
            width: 100%;
            float: left;
            padding: 16px;
            text-align: left;
            color: #303030;
            display: flex;
            align-items: center; }
            .wrapper .sidebar > ul > li .sub-menu > li > a i {
              color: #303030;
              font-size: 24px;
              margin-right: 8px; }
            .wrapper .sidebar > ul > li .sub-menu > li > a:hover {
              color: #922c88; }
              .wrapper .sidebar > ul > li .sub-menu > li > a:hover i {
                color: #922c88; }

@keyframes modal-animation {
  from {
    top: 0; }
  to {
    top: 50%; } }

@keyframes sidebar-animation {
  from {
    left: -100px; }
  to {
    left: 0; } }

@keyframes subsidebar-animation {
  from {
    left: 0px;
    z-index: -3; }
  to {
    left: 128px;
    z-index: -3; } }

.wrapper .sidebar-hide {
  display: none; }

.wrapper .content {
  width: 100%;
  min-height: calc(100vh - 108px);
  background-color: #f6f6f6;
  padding: 16px 32px;
  /*-webkit-box-shadow: inset 0px 5px 5px 1px rgba(184,184,184,0.63);
    -moz-box-shadow: inset 0px 5px 5px 1px rgba(184,184,184,0.63);
    box-shadow: inset 0px 5px 5px 1px rgba(184,184,184,0.63);*/
  margin-top: 108px; }
  @media only screen and (max-width: 768px) {
    .wrapper .content {
      padding: 0px;
      margin-top: 100px;
      min-height: calc(100vh - 80px); } }
  .wrapper .content .panel {
    margin: 16px 0px; }
    .wrapper .content .panel .panel-title {
      background-color: transparent;
      box-shadow: none;
      padding: 0;
      border-bottom: 1px solid #d7d7d7;
      margin-bottom: 16px;
      width: 100%; }
      @media only screen and (max-width: 768px) {
        .wrapper .content .panel .panel-title {
          padding: 0 15px !important; } }
      .wrapper .content .panel .panel-title > h1 {
        font-size: 24px;
        margin-bottom: 10px;
        font-weight: 600;
        width: 100%; }
      .wrapper .content .panel .panel-title .button-wrap {
        text-align: right;
        margin-top: 16px; }
        @media only screen and (max-width: 768px) {
          .wrapper .content .panel .panel-title .button-wrap {
            font-size: 12px;
            padding-left: 0 !important;
            padding-right: 0 !important; } }
      .wrapper .content .panel .panel-title > h6 {
        width: 100%;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 10px;
        color: #b0b0b0; }
    .wrapper .content .panel .container-title {
      width: 100%;
      margin-bottom: 16px; }
      .wrapper .content .panel .container-title > h1 {
        font-size: 18px;
        margin-bottom: 4px;
        width: 100%; }
      .wrapper .content .panel .container-title > h6 {
        width: 100%;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 10px;
        color: #b0b0b0; }
    .wrapper .content .panel > div {
      padding-top: 15px;
      padding-bottom: 15px;
      background-color: #fff;
      -webkit-box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
      -moz-box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
      box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); }
      @media only screen and (max-width: 768px) {
        .wrapper .content .panel > div {
          padding-left: 0 !important;
          padding-right: 0 !important; } }
      .wrapper .content .panel > div .title {
        border-bottom: 1px solid #d7d7d7;
        margin-bottom: 16px; }
        @media only screen and (max-width: 768px) {
          .wrapper .content .panel > div .title {
            padding-top: 15px; } }
        .wrapper .content .panel > div .title h1 {
          font-size: 24px;
          margin-bottom: 10px;
          font-weight: 600;
          padding-left: 0 !important; }
        .wrapper .content .panel > div .title .button {
          margin-left: auto;
          margin-right: 0; }
        .wrapper .content .panel > div .title > div {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: center;
          padding: 0; }
          @media only screen and (max-width: 768px) {
            .wrapper .content .panel > div .title > div {
              padding: 0 15px; } }
        .wrapper .content .panel > div .title h6 {
          padding-left: 0 !important;
          font-size: 13px;
          font-weight: 500;
          margin-bottom: 10px;
          color: #b0b0b0; }
  .wrapper .content .panel-list > div {
    background-color: transparent !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.wrapper .sidebar-active {
  padding-left: 128px !important;
  width: 100%; }

.wrapper .sub-menu-active {
  padding-left: 350px;
  width: 100%; }

.wrapper .google-map-marker {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 42px;
  margin-top: -63px;
  margin-left: -21px;
  color: #922c88; }

.wrapper .input-wrap {
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  float: left; }
  .wrapper .input-wrap label {
    width: 100%;
    font-size: 16px;
    margin-bottom: 8px;
    color: #303030; }
  .wrapper .input-wrap span {
    font-size: 14px; }
  .wrapper .input-wrap input[type=text], .wrapper .input-wrap select, .wrapper .input-wrap textarea {
    width: 100%;
    border: 1px solid #d7d7d7;
    padding: 8px;
    height: 36px;
    float: left; }
    .wrapper .input-wrap input[type=text]:focus, .wrapper .input-wrap select:focus, .wrapper .input-wrap textarea:focus {
      border: 1px solid #922c88;
      outline: none; }
  .wrapper .input-wrap .checkbox {
    border: 2px solid #D1D1D1;
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-right: 8px;
    border-radius: 4px;
    float: left; }
  .wrapper .input-wrap .checked {
    display: flex;
    align-items: center;
    justify-content: center; }
    .wrapper .input-wrap .checked::after {
      content: " ";
      display: block;
      border-right: 3px solid #922c88;
      border-bottom: 3px solid #922c88;
      width: 6px;
      height: 12px;
      transform: rotate(45deg); }
  .wrapper .input-wrap textarea {
    height: 100px; }
  .wrapper .input-wrap .select-field {
    border: 1px solid #d7d7d7;
    min-width: 100%;
    padding: 0;
    height: 36px;
    font-size: 14px; }
    .wrapper .input-wrap .select-field a {
      color: #303030; }
    .wrapper .input-wrap .select-field .dropdown-menu {
      min-width: 100%;
      max-height: 200px;
      overflow-y: auto; }
    .wrapper .input-wrap .select-field .dropdown-toggle {
      height: 36px;
      float: left;
      width: 100%; }
      .wrapper .input-wrap .select-field .dropdown-toggle::after {
        display: none; }
      .wrapper .input-wrap .select-field .dropdown-toggle::before {
        content: " ";
        display: block;
        border-top: 2px solid #303030;
        border-right: 2px solid #303030;
        width: 10px;
        height: 10px;
        transform: rotate(135deg);
        float: right;
        margin-top: 4px; }
  .wrapper .input-wrap .google-map-marker {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 42px;
    margin-top: -63px;
    margin-left: -21px;
    color: #922c88; }
  .wrapper .input-wrap .file-drop {
    border: 1px dotted #d7d7d7;
    width: 100%;
    height: 500px;
    position: relative;
    overflow-y: auto; }
    .wrapper .input-wrap .file-drop .dropzone {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      /*margin-left: -32px;
            margin-top: -32px;
            */ }
      .wrapper .input-wrap .file-drop .dropzone input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
      .wrapper .input-wrap .file-drop .dropzone > button {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -32px;
        margin-top: -32px;
        border: 0;
        background-color: transparent; }
        .wrapper .input-wrap .file-drop .dropzone > button i {
          font-size: 64px;
          color: #922c88; }
    .wrapper .input-wrap .file-drop .preview {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%; }
      .wrapper .input-wrap .file-drop .preview .options {
        text-align: center;
        width: 50%;
        height: 50%;
        position: absolute;
        top: 25%;
        left: 25%;
        background-color: rgba(255, 255, 255, 0.1);
        display: flex;
        align-items: center;
        justify-content: center; }
        .wrapper .input-wrap .file-drop .preview .options > i {
          font-size: 64px;
          cursor: pointer;
          display: none;
          color: rgba(146, 44, 136, 0.2); }
        .wrapper .input-wrap .file-drop .preview .options:hover {
          background-color: rgba(255, 255, 255, 0.8); }
          .wrapper .input-wrap .file-drop .preview .options:hover > i {
            display: block;
            color: #922c88; }
      .wrapper .input-wrap .file-drop .preview img {
        width: 50%;
        height: 50%;
        position: absolute;
        top: 25%;
        left: 25%;
        object-fit: contain;
        object-position: center; }
    .wrapper .input-wrap .file-drop .grid-layout {
      position: absolute;
      top: 0;
      left: 0;
      height: 650px; }
      .wrapper .input-wrap .file-drop .grid-layout .image-wrap {
        border: 1px dotted #d7d7d7;
        height: 150px;
        position: relative;
        background-color: #f6f6f6; }
        .wrapper .input-wrap .file-drop .grid-layout .image-wrap:hover .options {
          /*.mdi-delete{
                        color: rgb(146, 44, 136);
                    }
                    .mdi-home{
                        color: rgb(146, 44, 136);
                    }*/ }
        .wrapper .input-wrap .file-drop .grid-layout .image-wrap:hover .primary > i:nth-child(1) {
          color: #e3b110; }
        .wrapper .input-wrap .file-drop .grid-layout .image-wrap .info {
          padding: 16px;
          display: flex;
          justify-content: center;
          flex-direction: column; }
          .wrapper .input-wrap .file-drop .grid-layout .image-wrap .info input {
            margin-bottom: 8px; }
          .wrapper .input-wrap .file-drop .grid-layout .image-wrap .info button {
            margin: 0; }
        .wrapper .input-wrap .file-drop .grid-layout .image-wrap .primary > i:nth-child(1) {
          color: #e3b110; }
        .wrapper .input-wrap .file-drop .grid-layout .image-wrap .options {
          text-align: center;
          width: 100%; }
          .wrapper .input-wrap .file-drop .grid-layout .image-wrap .options > i {
            font-size: 32px;
            padding: 8px;
            cursor: pointer; }
          .wrapper .input-wrap .file-drop .grid-layout .image-wrap .options .mdi-delete {
            color: rgba(146, 44, 136, 0.2); }
          .wrapper .input-wrap .file-drop .grid-layout .image-wrap .options .mdi-home {
            color: #4556ac; }
        .wrapper .input-wrap .file-drop .grid-layout .image-wrap .mdi-delete:hover {
          color: #922c88; }
        .wrapper .input-wrap .file-drop .grid-layout .image-wrap .mdi-home:hover {
          color: #922c88; }
        .wrapper .input-wrap .file-drop .grid-layout .image-wrap img {
          width: 100%;
          height: 100px;
          object-fit: contain;
          object-position: center; }
    .wrapper .input-wrap .file-drop .gallery-grid .image-wrap {
      height: 300px; }

.wrapper .search-wrap {
  position: relative; }
  .wrapper .search-wrap input[type=text] {
    border-radius: 35px; }
  .wrapper .search-wrap .mdi {
    position: absolute;
    right: 10px;
    top: 3px;
    color: #922c88;
    font-size: 20px; }
  .wrapper .search-wrap .search-results {
    padding: 16px 0;
    border: 1px solid #d7d7d7;
    position: relative;
    top: 40px;
    width: 100%;
    z-index: 999;
    background-color: #fff;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column; }
    .wrapper .search-wrap .search-results .row {
      cursor: pointer;
      padding: 3px 0; }
      .wrapper .search-wrap .search-results .row:hover {
        background-color: #f1f1f1; }
    .wrapper .search-wrap .search-results img {
      width: 32px;
      height: 32px; }
    .wrapper .search-wrap .search-results span {
      padding: 0 16px; }

.wrapper .list-view {
  padding: 16px 0;
  border: 1px solid #d7d7d7;
  width: 100%;
  z-index: 999;
  background-color: #fff;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column; }
  .wrapper .list-view .row {
    cursor: pointer;
    padding: 3px 0; }
    .wrapper .list-view .row:hover {
      background-color: #f1f1f1; }
    .wrapper .list-view .row > div {
      align-items: center;
      display: flex; }
  .wrapper .list-view img {
    width: 32px;
    height: 32px; }
  .wrapper .list-view .mdi-delete {
    font-size: 24px;
    color: #922c88; }

.wrapper .sort-wrap {
  position: relative;
  width: 100%; }
  .wrapper .sort-wrap .select-field {
    margin-left: 15px;
    width: 200px;
    min-width: 200px;
    border-radius: 35px;
    background-color: #fff; }
    @media only screen and (max-width: 768px) {
      .wrapper .sort-wrap .select-field {
        margin-left: 0; } }

.wrapper .login-page {
  width: 100%;
  height: 100%; }
  .wrapper .login-page .modal {
    width: 300px;
    background-color: #fff;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    right: unset;
    bottom: unset;
    display: block;
    margin-top: -150px;
    margin-left: -150px;
    padding: 32px; }
    .wrapper .login-page .modal svg {
      width: 100%;
      padding: 0 16px; }
    .wrapper .login-page .modal .input-wrap {
      text-align: center; }
      .wrapper .login-page .modal .input-wrap input[type=text], .wrapper .login-page .modal .input-wrap input[type=password] {
        border: 1px solid #d7d7d7;
        width: 100%;
        padding: 8px;
        height: 36px; }
        .wrapper .login-page .modal .input-wrap input[type=text]:focus, .wrapper .login-page .modal .input-wrap input[type=password]:focus {
          border: 1px solid #922c88;
          outline: none; }
      .wrapper .login-page .modal .input-wrap .button {
        padding: 8px 32px;
        border-radius: 20px;
        width: 100%; }

.wrapper .map {
  height: 300px; }

.wrapper .selected {
  background-color: #9dbde5; }

.wrapper table {
  width: 100%;
  font-size: 15px;
  border-collapse: separate;
  border-spacing: 0 1em; }
  @media only screen and (max-width: 768px) {
    .wrapper table {
      overflow-x: scroll;
      display: block; } }
  .wrapper table > tr {
    width: 100%;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
    margin: 8px 0px;
    background-color: #fff;
    height: 64px;
    /*&:nth-child(even){
            background-color: #f6f6f6;
        }*/ }
    @media only screen and (max-width: 768px) {
      .wrapper table > tr {
        height: 164px; } }
    .wrapper table > tr:first-child {
      height: 32px; }
    .wrapper table > tr > th {
      height: 32px;
      padding: 10px 0 !important;
      cursor: pointer; }
      .wrapper table > tr > th:first-child {
        padding: 10px 10px !important; }
      @media only screen and (max-width: 768px) {
        .wrapper table > tr > th {
          padding: 10px !important; } }
    .wrapper table > tr .action-head {
      text-align: right; }
    .wrapper table > tr > td {
      height: 64px;
      padding: 20px 0 !important; }
      @media only screen and (max-width: 768px) {
        .wrapper table > tr > td {
          padding: 0 10px !important; } }
      .wrapper table > tr > td .input-wrap {
        margin-bottom: 0;
        padding-right: 16px; }
      .wrapper table > tr > td:first-child {
        padding: 0 !important; }
      .wrapper table > tr > td img {
        width: 64px;
        height: 64px;
        object-fit: cover; }
        @media only screen and (max-width: 768px) {
          .wrapper table > tr > td img {
            display: none; } }
      .wrapper table > tr > td input[type=text], .wrapper table > tr > td input[type=number] {
        border: none;
        background-color: transparent;
        cursor: pointer;
        margin-right: 4px;
        font-size: 20px;
        padding: 0 4px; }
      .wrapper table > tr > td input[type=text]:focus {
        border: 1px solid #922c88;
        outline: none;
        cursor: text; }
      .wrapper table > tr > td .number-input {
        width: 70px; }
      .wrapper table > tr > td .button {
        margin-bottom: 8px;
        background-color: transparent !important;
        font-size: 16px !important;
        height: 44px; }
        .wrapper table > tr > td .button .mdi {
          color: #922c88;
          font-size: 24px; }
        .wrapper table > tr > td .button > img {
          width: 26px;
          height: 26px; }
    .wrapper table > tr .action {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 64px; }
      .wrapper table > tr .action .button {
        padding: 10px 5px; }
      .wrapper table > tr .action .mdi-close {
        color: red !important;
        font-size: 32px !important; }
      .wrapper table > tr .action .mdi-check {
        color: green !important;
        font-size: 32px !important; }
      .wrapper table > tr .action img {
        width: 64px !important;
        height: auto !important; }
      .wrapper table > tr .action .disabled {
        color: #b0b0b0 !important; }
      .wrapper table > tr .action .mdi-close {
        color: red !important;
        font-size: 32px !important; }
      .wrapper table > tr .action .cash-enabled {
        color: green !important; }
      .wrapper table > tr .action .cash-disabled {
        color: red !important; }
      @media only screen and (max-width: 768px) {
        .wrapper table > tr .action {
          flex-wrap: wrap;
          height: 164px !important; } }
  .wrapper table .stock-alert > td input[type=number] {
    border: 1px solid red; }
  .wrapper table .text-row td, .wrapper table .text-row th {
    padding: 0 16px; }
  .wrapper table .text-row:first-child {
    background-color: transparent;
    height: 40px;
    box-shadow: none; }

.wrapper .table {
  width: 100%; }
  @media only screen and (max-width: 768px) {
    .wrapper .table {
      padding: 0 15px; } }

.wrapper .table-no-image table {
  border-spacing: 0 8px; }
  .wrapper .table-no-image table > tr {
    height: 32px; }
    .wrapper .table-no-image table > tr > th {
      height: 32px;
      padding: 10px 0 !important; }
      .wrapper .table-no-image table > tr > th:first-child {
        padding: 10px 10px !important; }
      @media only screen and (max-width: 768px) {
        .wrapper .table-no-image table > tr > th {
          padding: 10px !important; } }
    .wrapper .table-no-image table > tr > td {
      height: 32px;
      padding: 10px 0 !important; }
      .wrapper .table-no-image table > tr > td:first-child {
        padding: 10px 10px !important; }

.wrapper .pagination {
  float: left; }
  .wrapper .pagination > li {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background-color: #E5E5E5;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #303030;
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer; }
    .wrapper .pagination > li a {
      color: #303030;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
    .wrapper .pagination > li:hover {
      color: #fff;
      text-decoration: none;
      background-color: #2e3e50; }
      .wrapper .pagination > li:hover a {
        text-decoration: none;
        color: #fff; }
  .wrapper .pagination .break-me:hover {
    background-color: #E5E5E5;
    cursor: default; }
    .wrapper .pagination .break-me:hover a {
      color: #303030; }
  .wrapper .pagination .active {
    color: #fff;
    background-color: #2e3e50; }
    .wrapper .pagination .active a {
      color: #fff; }
  .wrapper .pagination .previous {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    .wrapper .pagination .previous::after {
      content: ' ';
      border-top: 0;
      border-right: 0;
      border-bottom: 2px solid #303030;
      border-left: 2px solid #303030;
      padding: 4px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      display: block;
      position: absolute;
      top: 16px;
      left: 16px; }
    .wrapper .pagination .previous:hover::after {
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff; }
  .wrapper .pagination .next {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    .wrapper .pagination .next::after {
      content: ' ';
      border-top: 0;
      border-right: 0;
      border-bottom: 2px solid #303030;
      border-left: 2px solid #303030;
      padding: 4px;
      -webkit-transform: rotate(-135deg);
      transform: rotate(-135deg);
      width: 10px;
      height: 10px;
      display: block;
      position: absolute;
      top: 16px;
      left: 16px; }
    .wrapper .pagination .next:hover::after {
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff; }

.wrapper .button {
  background-color: #922c88;
  border-radius: 35px;
  padding: 10px 20px;
  color: #fff;
  font-size: 14px;
  margin-right: 15px;
  border: 0; }
  .wrapper .button > img {
    width: 20px; }

.wrapper .prompt-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 53, 68, 0.5);
  z-index: 999999999; }
  .wrapper .prompt-modal > div {
    width: 400px;
    -webkit-box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
    position: fixed;
    animation-name: modal-animation;
    animation-duration: 0.3s;
    top: 50%;
    left: 50%;
    margin-left: -200px;
    margin-top: -300px;
    background-color: #fff;
    padding-bottom: 16px;
    text-align: center; }
    .wrapper .prompt-modal > div > h3 {
      font-size: 17px;
      width: 100%;
      text-align: left;
      padding: 16px; }
      .wrapper .prompt-modal > div > h3 > i {
        font-size: 18px;
        color: #922c88;
        float: right;
        color: #d7d7d7;
        cursor: pointer; }
    .wrapper .prompt-modal > div .spacer {
      margin: 0 0 16 0px; }
    .wrapper .prompt-modal > div .button-wrap {
      text-align: right; }
      .wrapper .prompt-modal > div .button-wrap .button {
        padding-left: 32px;
        padding-right: 32px; }
        .wrapper .prompt-modal > div .button-wrap .button:nth-child(2) {
          background-color: #4556ac;
          border-color: #4556ac;
          color: #fff; }
    .wrapper .prompt-modal > div .input-wrap {
      padding: 16px; }

.wrapper .multilang-field {
  width: 100%;
  height: 40px;
  list-style: none;
  padding: 0;
  margin: 0;
  border-left: 1px solid #d7d7d7; }
  .wrapper .multilang-field li {
    float: left;
    padding: 8px 16px;
    background-color: #f6f6f6;
    border-top: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    cursor: pointer;
    text-transform: uppercase; }
    .wrapper .multilang-field li:hover {
      background-color: #fff;
      color: #922c88;
      font-weight: 600; }
  .wrapper .multilang-field .active {
    background-color: #fff;
    color: #922c88;
    font-weight: 600; }

.wrapper .spacer {
  width: 100%;
  height: 1px;
  background-color: #d7d7d7;
  margin: 32px 0; }

.wrapper .loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 53, 68, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999; }

.wrapper .lds-ripple {
  display: inline-block;
  position: relative;
  width: 128px;
  height: 128px; }

.wrapper .lds-ripple div {
  position: absolute;
  border: 8px solid #922c88;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; }

.wrapper .lds-ripple div:nth-child(2) {
  animation-delay: -0.5s; }

@keyframes lds-ripple {
  0% {
    top: 56px;
    left: 56px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: -1px;
    left: -1px;
    width: 120px;
    height: 120px;
    opacity: 0; } }

.wrapper .react-switch {
  height: 20px !important;
  width: 44px !important; }
  .wrapper .react-switch > span {
    height: 24px !important;
    width: 24px !important;
    top: -4px !important; }

.wrapper .spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9); }

.wrapper .spinner {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #922c88;
  background: -moz-linear-gradient(left, #922c88 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #922c88 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #922c88 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #922c88 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #922c88 10%, rgba(255, 255, 255, 0) 42%);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); }

.wrapper .spinner:before {
  width: 50%;
  height: 50%;
  background: #922c88;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: ''; }

.wrapper .spinner:after {
  background: #fff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.wrapper .box {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 150px; }
  .wrapper .box .mdi {
    color: #922c88;
    font-size: 32px; }
  .wrapper .box h6 {
    font-size: 18px; }

.wrapper .chartjs-panel {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  padding: 16px;
  margin-bottom: 30px; }
  .wrapper .chartjs-panel h6 {
    color: #b0b0b0;
    margin-bottom: 30px; }
  .wrapper .chartjs-panel .container-title div {
    float: right; }
    .wrapper .chartjs-panel .container-title div .button {
      border: 0;
      border-radius: 0;
      padding: 5px 8px;
      margin-left: 5px; }

.payed {
  color: green;
  font-weight: 800; }

.not-payed {
  color: #922c88;
  font-weight: 800; }

.order-account * {
  font-family: 'Trebuchet MS', 'Verdana', 'Arial', 'sans-serif' !important; }

.order-account {
  min-height: 100vh;
  background-color: #fff;
  overflow: scroll; }
  .order-account .container {
    min-width: 1140px;
    overflow: auto; }
  .order-account .top-container {
    width: 100%;
    float: left;
    margin-top: 50px; }
  .order-account .note {
    float: left;
    width: 100%;
    margin-top: 16px;
    font-weight: 800; }
    .order-account .note span {
      font-weight: 400; }
  .order-account .left {
    float: left;
    margin-top: 62px;
    width: 70%;
    font-weight: 700;
    font-size: 16px;
    padding-bottom: 0px; }
  .order-account .right {
    float: right;
    width: 30%;
    margin-top: 62px; }
  .order-account .info {
    margin-top: 110px;
    float: left;
    width: 60%; }
    .order-account .info h1 {
      font-size: 35px;
      font-weight: 700; }
    .order-account .info .title {
      float: left;
      width: 15%; }
      .order-account .info .title h2 {
        font-size: 25px;
        font-weight: 700; }
    .order-account .info .datas {
      float: right;
      width: 85%; }
      .order-account .info .datas h2 {
        font-size: 25px;
        font-weight: 400; }
  .order-account .komitet {
    margin-top: 20px;
    float: left;
    width: 40%; }
    .order-account .komitet h3 {
      font-size: 25px;
      font-weight: 400;
      position: relative;
      left: 20px;
      top: 10px;
      background-color: #fff;
      width: 200px; }
    .order-account .komitet .box {
      padding: 20px 0 20px 20px;
      background-color: #e4e4e4;
      width: 100%;
      border: 2px solid black; }
      .order-account .komitet .box h6 {
        font-size: 16px;
        line-height: 14px; }
    .order-account .komitet h4 {
      text-align: end;
      font-weight: 400;
      margin-top: 5px; }
  .order-account .isporuka {
    float: right; }
    .order-account .isporuka .box {
      min-height: 198px; }
    .order-account .isporuka h3 {
      width: 200px; }
  .order-account .naslov {
    padding-top: 420px;
    margin-bottom: 30px;
    text-align: center; }
    .order-account .naslov h1 {
      font-size: 28px; }
  .order-account .heidingTable {
    width: 100%;
    display: flex; }
    .order-account .heidingTable h3 {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 0; }
    .order-account .heidingTable .col1 {
      width: 33.33%;
      text-align: start; }
    .order-account .heidingTable .col2 {
      width: 33.33%;
      text-align: start;
      margin-left: 75px; }
    .order-account .heidingTable .col3 {
      text-align: end;
      width: 33.33%;
      padding-top: 65px; }
    .order-account .heidingTable .col4 {
      width: 33.33%; }
    .order-account .heidingTable .col5 {
      text-align: end;
      width: 33.33%; }
  .order-account .nasloviTabele {
    width: 100%;
    text-align: center; }
    .order-account .nasloviTabele td {
      font-size: 12px;
      background-color: #e4e4e4;
      border: 1px solid black;
      font-weight: 700; }
    .order-account .nasloviTabele .white td {
      background-color: #fff !important; }
  .order-account .underTableLeft {
    float: left;
    width: 78%;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    padding-top: 10px;
    text-align: center;
    padding-bottom: 30px;
    min-height: 250px; }
    .order-account .underTableLeft h6 {
      font-size: 13px;
      font-weight: 700; }
    .order-account .underTableLeft h5 {
      padding-bottom: 10px;
      font-size: 12px;
      margin-bottom: 0; }
    .order-account .underTableLeft .clanovi {
      text-align: start; }
      .order-account .underTableLeft .clanovi p {
        font-size: 10px;
        line-height: 8px;
        font-weight: 600;
        margin-left: 5px; }
  .order-account .underTableRigth h6 {
    margin-top: 10px;
    font-size: 15px; }
    .order-account .underTableRigth h6 span {
      font-weight: 700; }
  .order-account .underTableRigth .leftUnderTable {
    width: 15%;
    float: left;
    text-align: end;
    border-bottom: 1px solid black; }
  .order-account .underTableRigth .rightUnderTable {
    border-bottom: 1px solid black;
    width: 7%;
    float: right;
    text-align: end; }
  .order-account .noBorder {
    border: 0 !important; }
  .order-account .potpis {
    padding-bottom: 20px;
    padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; }
    .order-account .potpis h6 {
      margin-top: 20px;
      font-weight: 700;
      font-size: 20px;
      width: 200px; }
    .order-account .potpis h5 {
      font-size: 18px;
      font-weight: 700; }
    .order-account .potpis .col1 {
      width: 40%; }
      .order-account .potpis .col1 .cjena h5 {
        text-align: start; }
      .order-account .potpis .col1 h6 {
        padding-top: 20px; }
      .order-account .potpis .col1 hr {
        margin-top: 30px;
        background-color: black;
        width: 215px;
        margin-left: -10px;
        border-bottom: 3px solid black; }
    .order-account .potpis .col2 {
      width: 60%;
      margin-left: 75px; }
      .order-account .potpis .col2 h6 {
        padding-top: 20px; }
      .order-account .potpis .col2 hr {
        margin-top: 30px;
        background-color: black;
        width: 215px;
        margin-left: -10px;
        border-bottom: 3px solid black; }
    .order-account .potpis .col3 h6 {
      margin-top: 40px; }
    .order-account .potpis .col3 hr {
      margin-top: 30px;
      background-color: black;
      width: 215px;
      margin-left: -10px;
      border-bottom: 3px solid black; }

.center-me {
  position: absolute;
  bottom: 16px;
  right: 32px;
  outline: none;
  border: 0;
  background: #fff;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  .center-me i {
    font-size: 32px;
    color: #922c88; }

.space-between {
  justify-content: space-between; }

html, body {
  min-height: 100%;
  min-height: 100vh;
  position: relative;
  width: 100%;
  width: 100vw;
  overflow-x: hidden;
  font-family: 'Barlow', sans-serif;
  background-color: #f6f6f6; }

html, body, h1, h2, h3, h4, h5, h6, p, a, span, div {
  font-family: 'Barlow', sans-serif; }

button {
  cursor: pointer; }

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 5px; }

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #D7D7D7;
  border-radius: 5px;
  cursor: pointer; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #D7D7D7;
  cursor: pointer; }
